/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import { Hero, Statement } from "../components";
// eslint-disable-next-line import/named
import { ResearchComponent } from "../components/ResearchDocument";
import { buildMenu, Layout } from "../layouts";
import { getImage } from "@bond-london/gatsby-graphcms-components";

// const component: React.FC<PageProps<Pick<Query, "graphCmsSectorPage">>> = ({
//   path,
//   data: { graphCmsSectorPage: sectorPage },
// }) => {
//   console.log(sectorPage);
//   if (!sectorPage) return null;
//   return (
//     <Layout
//       pagePath={path}
//       menu={buildMenu(sectorPage.menu)}
//       footerMenu={buildMenu(sectorPage.footerMenu)}
//       keywords={sectorPage.keywords}
//       title={sectorPage.pageTitle || sectorPage.sector}
//       description={sectorPage.description}
//     >
//       <Hero
//         title="Partners in Performance Energy Transition Insights"
//         video={getVideo(sectorPage.heroVideo)}
//         image={getImage(sectorPage.heroImage)}
//         short={true}
//         treatment={sectorPage.heroTreatment}
//         showSearch={true}
//       />
//       <Statement
//         title=""
//         headline="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
//         id={""}
//         className="custom-class"
//       />
//       <ResearchComponent name="" />
//     </Layout>
//   );
// };

// export default component;

// export const sectorPageQuery = graphql`
//   query sectorPageQueryAndSectorPageQuery($id: String) {
//     graphCmsSectorPage(id: { eq: $id }) {
//       sectorType
//       pageTitle
//       keywords
//       description
//       menu {
//         ...MenuFragment
//       }
//       businessChallengesIntroduction
//       businessChallenges {
//         headline
//         id
//         content {
//           cleaned
//         }
//       }
//       heroImage {
//         ...ImageFragment
//       }
//       statement {
//         cleaned
//       }
//       heroVideo {
//         ...VideoFragment
//       }
//       heroTreatment
//       id
//       keyStatistic
//       leadership {
//         ...ProfileFragment
//       }
//       leadershipTitle
//       quote
//       quoteFrom {
//         ...ProfileFragment
//       }
//       infographicIntroduction {
//         cleaned
//       }
//       infographicDetails {
//         cleaned
//       }
//       genericInfographicText {
//         cleaned
//       }
//       sector
//       slug
//       statisticDetails
//       storiesTitle
//       storiesImage {
//         ...ImageFragment
//       }
//       successes {
//         __typename
//         ... on GraphCMS_CaseStudy {
//           ...CaseStudyReferenceFragment
//         }
//         ... on GraphCMS_Story {
//           ...StoryReferenceFragment
//         }
//         ... on GraphCMS_Insight {
//           ...InsightReferenceFragment
//         }
//       }
//       form {
//         ...FormFragment
//       }
//     }
//   }
// `;

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  console.log(sectorPage);
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="capital-projects-research |Partners in Performance Capital Projects Insights"
      description={sectorPage?.description}
    >
      <Hero
        title="Partners in Performance Capital Projects Insights"
        video={
          "/static/f8a7c20ccab49185f7ea10212ae7d436/small_Capital-Projects-Hero-Video.mp4"
        }
        image={getImage(sectorPage?.heroImage)}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline="A collection of analysis, research and stories about our capabilities from our Capital Project Experts."
        id={""}
        className="custom-class"
      />
      <section
        data-component="ResearchComponent"
        className="relative max-width z-10 "
      >
        <div className="content-grid grid-flow-row-dense">
          {nodes.map((n) => console.log(n))}
          {nodes.map(
            (n) =>
              // eslint-disable-next-line react/jsx-key
              // if (n.documentLocate =="Capital-Project")
              n.documentLocate == "Capital-Project" && (
                <ResearchComponent
                  name={n.documentDescription}
                  url={n.documentThumbnail.url}
                  DocumentUrl={n.documents.url}
                />
              )
          )}
        </div>
      </section>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentLocate
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
